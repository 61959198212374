import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_MEDIA_URL } from "../../config/webService";
import { currentLanguage, getFormatedDate } from "../../util/common";
import { useAppNavigation } from "../../hooks";
import { Button } from "./";
import { CampaignServices } from "../../services";
import { Loader } from "../shared";
import CampaignViewModal from "./CampaignViewModal";
import TREE from "../../images/tree.png";

const FlipCard = ({ offer, image }) => {
  const { t } = useTranslation();
  const language = currentLanguage();
  const { toOffers, toUrlWithData, localeRoutes, toRequestAQuote } = useAppNavigation();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const quotationService = offer?.isQuotationService;

  const getOffer = async () => {
    setLoading(true);
    try {
      const data = await CampaignServices.getCampaign(offer.slugUrl);
      if (!data) toOffers();

      return data;
    } catch (e) {
      console.error(e);
    }
  };
  const goToBooking = async () => {
    if (quotationService) {
      toRequestAQuote(offer?.slugUrl);
    } else {
      const data = await getOffer();
      toUrlWithData(localeRoutes.bringIt[language], {
        service: { ...data, id: data._id },
        offer: true
      });
    }
  };

  const handleModal = () => {
    setLoading(true);
    setShowModal(true);
  };

  return (
    <>
      <Loader show={loading} />

      <div
        className={`flip-card col-md-6 col-lg-4 col-xl-3 ${offer?.isCampaignActive && "foundation-day"} ${quotationService && "flip-card-quote"}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div
              className="image"
              style={{
                background: `url(${GET_MEDIA_URL + image?.[language]})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "300px"
              }}>
              <h1>{offer?.name["value_" + language]}</h1>
              {offer?.isCampaignActive && (
                <>
                  <div className="expiry-date">
                    <span>
                      {t("services.validity")}:{" "}
                      {getFormatedDate(offer?.campaignEndDate, "YYYY-MM-DD")}
                    </span>
                  </div>

                  <div className="ribbon-wrapper">
                    <div className="ribbon-tag"> {t("services.foundingDay")}</div>
                  </div>
                </>
              )}
            </div>
            {offer?.isCampaignActive ? (
              <div className="details">
                <div className="main">
                  <div className="gap-2 d-flex justify-content-center mt-1">
                    {[0, 1, 2]?.map(() => {
                      return (
                        <img
                          src={TREE}
                          style={{ width: "16px", height: "16px", objectFit: "contain" }}
                        />
                      );
                    })}
                  </div>
                  <div>
                    <span
                      style={{
                        textDecoration: offer?.isCampaignActive ? "line-through" : "none"
                      }}>
                      {offer?.price}
                    </span>{" "}
                    {offer?.isCampaignActive ? offer?.campaignPrice : ""} {t("offer.sar")}
                  </div>

                  <div className="gap-2 d-flex justify-content-center mt-1">
                    {[0, 1, 2]?.map(() => {
                      return (
                        <img
                          src={TREE}
                          style={{ width: "16px", height: "16px", objectFit: "contain" }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              offer?.price > 0 && (
                <div className="details">
                  <bold>
                    <span
                      style={{ textDecoration: offer?.isCampaignActive ? "line-through" : "none" }}>
                      {offer?.price}
                    </span>{" "}
                    {offer?.isCampaignActive ? offer?.campaignPrice : ""} {t("offer.sar")}
                  </bold>
                </div>
              )
            )}
          </div>
          <div className="flip-card-back">
            <span className="name">{offer?.name["value_" + language]}</span>
            <p
              className="shortDesc"
              dangerouslySetInnerHTML={{
                __html:
                  offer?.detail?.["value_" + language] || offer?.description?.["value_" + language]
              }}
            />
            {offer?.isCampaignActive && (
              <Button type="small-roundedLeft" action={() => handleModal()}>
                {t("services.seeMore")}
              </Button>
            )}
          </div>
        </div>
        <div className="details">
          <Button className="btn" action={goToBooking}>
            {quotationService ? t("quotations.getQuoteBtn") : t("offer.buyAndBook")}
          </Button>
        </div>
      </div>

      <CampaignViewModal
        {...{
          show: showModal,
          onCancel: () => setShowModal(false),
          offer,
          loading,
          setLoading,
          goToBooking
        }}
      />
    </>
  );
};
export default FlipCard;
